<!--门禁模块--考勤记录-->
<template>
  <div
    id="attendanceRecord"
    v-loading="loading"
    :element-loading-text="`${$t('attendance.a1')}....`"
  >
    <div class="header">
      <my-headertitle>{{ $t("attendance.a2") }}</my-headertitle>
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="3">
            <a-form-model-item :label="$t('attendance.a3')">
              <a-input
                :maxLength="30"
                allow-clear
                v-model="employeeBelongs"
                :placeholder="$t('attendance.a17')"
                style="width: 100%"
                v-InputText
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="3">
            <a-form-model-item :label="$t('attendance.a4')">
              <a-input
                :maxLength="30"
                allow-clear
                v-model="employeeId"
                :placeholder="$t('attendance.a18')"
                style="width: 100%"
                v-Input-NumberText
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="3">
            <a-form-model-item :label="$t('attendance.a5')">
              <a-input
                :maxLength="30"
                allow-clear
                v-model="employeeName"
                :placeholder="$t('attendance.a19')"
                style="width: 100%"
                v-InputText
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item :label="$t('attendance.a6')" prop="date1">
              <a-date-picker
                v-model="form.date1"
                :disabled-date="disabledStartDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                @change="handleCalendarChange"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item :label="$t('attendance.a7')" prop="date2">
              <a-date-picker
                v-model="form.date2"
                :disabled-date="disabledEndDate"
                show-time
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">
                {{ $t("attendance.a8")}}
              </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="main" ref="main" v-table-size="tableSize">
      <my-tabletitle>
        {{ $t("attendance.a9") }}
        <template v-slot:name>
          <a-button
            type="primary"
            icon="file-text"
            size="small"
            ghost
            :disabled="list.length == 0"
            @click="csvExport"
            style="margin-right: 20px"
          >
            {{ $t("alarm.a51") }}
          </a-button>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="list"
        :pagination="pagination"
        :scroll="size"
        :rowKey="(record) => record.key"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { attendanceData } from "../../api/door";
import { tableSort, csvDownload, tableTitleDisplay ,tableIndexDisplay } from "../../utils/utils";
import moment from "moment";

export default {
  name: "attendanceRecord",
  data() {
    return {
      form: {
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      employeeName: undefined,
      employeeId: undefined,
      employeeBelongs: undefined,
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          {
            required: true,
            message: () => {
              return this.$t("attendance.a10");
            },
            trigger: "change",
          },
        ],
        date2: [
          {
            required: true,
            message: () => {
              return this.$t("attendance.a10");
            },
            trigger: "change",
          },
        ],
      },
      size: { x: 800, y: 240 },
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("attendance.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      columns: [
        {
          id:"1",
          title: "NO",
          dataIndex:"NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 80,
        },
        {
          id:"2",
          title: this.$t("attendance.a12"),
          dataIndex: "employeeID",
          sorter: (a, b) => {
            return tableSort(a, b, "employeeID");
          },
          align: "center",
        },
        {
          id:"3",
          title: this.$t("attendance.a13"),
          dataIndex: "employeeName",
          sorter: (a, b) => {
            return tableSort(a, b, "employeeName");
          },
          align: "center",
        },
        {
          id:"4",
          title: this.$t("attendance.a14"),
          dataIndex: "date",
          sorter: (a, b) => {
            return tableSort(a, b, "date");
          },
          align: "center",
        },
        {
          id:"5",
          title: this.$t("attendance.a15"),
          dataIndex: "startTime",
          sorter: (a, b) => {
            return tableSort(a, b, "startTime");
          },
          align: "center",
        },
        {
          id:"6",
          title: this.$t("attendance.a16"),
          dataIndex: "endTime",
          sorter: (a, b) => {
            return tableSort(a, b, "endTime");
          },
          align: "center",
        },
      ],
      column: [],
      displayContent:JSON.parse(localStorage.getItem("attendanceRecord_tableTitle")) || [],
    };
  },
  watch:{
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "attendanceRecord_tableTitle";
        const data = tableIndexDisplay(name,columns,newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // 表格表头
    const name = "attendanceRecord_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("attendanceRecord")
      if(indexDom !== null){
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 118.5 - title;
        this.size.y = tableHeight;
      }
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            startDate: this.form.date1.format("YYYY-MM-DD HH:mm:ss"),
            endDate: this.form.date2.format("YYYY-MM-DD HH:mm:ss"),
            employeeName: this.employeeName,
            employeeId: this.employeeId,
            employeeBelongs: this.employeeBelongs,
          };
          // console.log(data);
          attendanceData(data)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              for (let i = 0; i < data.length; i++) {
                data[i].key = i;
              }
              this.list = data;
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // CSV导出
    csvExport() {
      const date1 = this.form.date1.format("YYYYMMDD");
      const date2 = this.form.date2.format("YYYYMMDD");
      const name = this.$t("attendance.a2");
      const fileName = `${date1}~${date2}${name}`;
      const data = this.list;
      const columns = this.columns;
      csvDownload(data, columns, fileName);
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      this.form.date2 = moment(val).endOf("day");
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const nowValue = moment();
      if (!startValue || !nowValue) {
        return false;
      }
      // 禁用大于计算机日期的日期值
      return startValue.valueOf() > nowValue.valueOf();
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      const _value = JSON.stringify(startValue);
      const value = JSON.parse(_value);
      const nowValue = moment().endOf("day");
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        endValue.valueOf() > nowValue.valueOf() ||
        moment(value).add(1, "months").valueOf() <= endValue.valueOf()
      );
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
#attendanceRecord {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  height: 150px;
  padding: 0 20px;
  overflow: hidden;
}
.main {
  height: calc(100% - 150px);
  width: 100%;
  padding: 0px 20px;
  overflow: hidden;
}
div /deep/.ant-form label{
  white-space:nowrap
}
</style>